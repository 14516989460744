export const TITLES = {
	BASE: 'VVD100',
	HOME: 'Home',
	LOGIN: 'Inloggen',
	FORGOT_PASSWORD: 'Pincode vergeten?',
	LOCATION_REQUIRED: 'Locatie toestaan',
	RESET_PASSWORD: 'Pincode instellen',
	CONFIRM_PIN: 'Bevestig je pincode',
	LEAVE_CONVERSATION: 'Gesprek verlaten?',
	FINISH_CAMPAIGN: 'Actie afronden?',
	CONFIRM_PASSWORD: 'Bevestig je pincode',
	NOT_CONNECTED: '',
};
