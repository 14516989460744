// Imports => Constants
import { ENDPOINTS } from '@constants';

export class ConversationAPI {
	constructor(Client, config) {
		this.Client = Client;
		this.config = config;
	}

	absent = data => {
		return this.Client.post(ENDPOINTS.CONVERSATION.ABSENT, data).then(
			response => response.data.data
		);
	};

	not_interested = data => {
		return this.Client.post(ENDPOINTS.CONVERSATION.NOT_INTERESTED, data).then(
			response => response.data.data
		);
	};

	finish = data => {
		return this.Client.post(ENDPOINTS.CONVERSATION.FINISH, data).then(
			response => response.data.data
		);
	};

	get = data => {
		return this.Client.get(ENDPOINTS.CONVERSATION.GET, { params: data }).then(
			response => response.data.data
		);
	};
}

export default ConversationAPI;
