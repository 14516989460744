export const INSTRUCTIONS = {
	IOS: 'https://support.apple.com/en-in/guide/safari/ibrwe2159f50/mac',
	SAFARI: 'https://support.apple.com/en-in/guide/safari/ibrwe2159f50/mac',
	ANDROID:
		'https://support.google.com/chrome/answer/142065?co=GENIE.Platform%3DAndroid&hl=nl',
	CHROME:
		'https://support.google.com/chrome/answer/142065?co=GENIE.Platform%3DAndroid&hl=nl',
	FIREFOX:
		'https://support.mozilla.org/en-US/questions/1237471#:~:text=right%2Dclick%20a%20blank%20area,icon%2C%20then%20More%20Information%20%3E%20Permissions',
};

export default INSTRUCTIONS;
