export const ICONS = {
	GROUPS: 'groups',
	GROUPS_OUTLINE: 'groups-outline',
	EDIT: 'edit',
	EDIT_OUTLINE: 'edit-outline',
	HOME_REMOVE: 'home-remove',
	HOME_REMOVE_OUTLINE: 'home-remove-outline',
	THUMB_UP: 'thumb-up',
	THUMB_DOWN: 'thumb-down',
	THUMB_DOWN_OUTLINE: 'thumb-down-outline',
	THUMB_UP_OUTLINE: 'thumb-up-outline',
	INCANDESCENT_TT_DOWN: 'incandescent-tt-down',
	INCANDESCENT_TT: 'incandescent-tt',
	INCANDESCENT_DOWN: 'incandescent-down',
	INCANDESCENT: 'incandescent',
	LIGHTBULB_ON_OUTLINE: 'lightbulb-on-outline',
	LIGHTBULB_ON: 'lightbulb-on {',
	EMAIL_CHECK_OUTLINE: 'email-check-outline',
	WEST: 'west',
	EAST: 'east',
	NORTH: 'north',
	SOUTH: 'south',
	ACCOUNT: 'account',
	ACCOUNT_BUBBLE: 'account-bubble',
	ACCOUNT_CIRCLE_OUTLINE: 'account-circle-outline',
	ACCOUNT_DETAILS: 'account-details',
	ACCOUNT_DETAILS_OUTLINE: 'account-details-outline',
	ACCOUNT_OUTLINE: 'account-outline',
	ALERT_CIRCLE: 'alert-circle',
	ALERT_CIRCLE_OUTLINE: 'alert-circle-outline',
	ALERT_OCTAGRAM: 'alert-octagram',
	ALERT_OCTAGRAM_OUTLINE: 'alert-octagram-outline',
	ARROW_DOWN: 'arrow-down',
	ARROW_LEFT: 'arrow-left',
	ARROW_RIGHT: 'arrow-right',
	ARROW_UP: 'arrow-up',
	CALENDAR_CLOCK: 'calendar-clock',
	CALENDAR_EDIT: 'calendar-edit',
	CALENDAR_SYNC: 'calendar-sync',
	CALENDAR_SYNC_OUTLINE: 'calendar-sync-outline',
	CARD_ACCOUNT_DETAILS: 'card-account-details',
	CARD_ACCOUNT_DETAILS_OUTLINE: 'card-account-details-outline',
	CART: 'cart',
	CART_ARROW_DOWN: 'cart-arrow-down',
	CART_MINUS: 'cart-minus',
	CART_OUTLINE: 'cart-outline',
	CART_PLUS: 'cart-plus',
	CHECK: 'check',
	CHECK_ALL: 'check-all',
	CHECKBOX_MARKED_CIRCLE_OUTLINE: 'checkbox-marked-circle-outline',
	CHEVRON_DOWN: 'chevron-down',
	CHEVRON_LEFT: 'chevron-left',
	CHEVRON_RIGHT: 'chevron-right',
	CHEVRON_UP: 'chevron-up',
	CLOCK_ALERT: 'clock-alert',
	CLOCK_ALERT_OUTLINE: 'clock-alert-outline',
	CLOCK_HISTORY: 'clock-history',
	CLOSE: 'close',
	CLOSE_CIRCLE: 'close-circle',
	CLOSE_CIRCLE_OUTLINE: 'close-circle-outline',
	CLOSE_THICK: 'close-thick',
	COMMENT_QUESTION_OUTLINE: 'comment-question-outline',
	CONTENT_COPY: 'content-copy',
	COOKIE: 'cookie',
	DELETE: 'delete',
	DELETE_EMPTY_OUTLINE: 'delete-empty-outline',
	DELETE_OUTLINE: 'delete-outline',
	DELETE_VARIANT: 'delete-variant',
	DELTA: 'delta',
	DOTS_HORIZONTAL: 'dots-horizontal',
	DOTS_VERTICAL: 'dots-vertical',
	EMAIL: 'email',
	EMAIL_OUTLINE: 'email-outline',
	EMAIL_SEND_OUTLINE: 'email-send-outline',
	FACE_AGENT: 'face-agent',
	FACEBOOK: 'facebook',
	FILTER: 'filter',
	FILTER_OUTLINE: 'filter-outline',
	FILTER_VARIANT: 'filter-variant',
	HELP: 'help',
	HELP_CIRCLE: 'help-circle',
	HELP_CIRCLE_OUTLINE: 'help-circle-outline',
	HOME: 'home',
	HOME_CITY: 'home-city',
	HOME_CITY_OUTLINE: 'home-city-outline',
	HOME_OUTLINE: 'home-outline',
	HOME_VARIANT: 'home-variant',
	HOME_VARIANT_OUTLINE: 'home-variant-outline',
	INFORMATION: 'information',
	INFORMATION_OUTLINE: 'information-outline',
	INSTAGRAM: 'instagram',
	LINKEDIN: 'linkedin',
	LINKEDIN_OUTLINE: 'linkedin-outline',
	LOCK: 'lock',
	LOCK_OPEN: 'lock-open',
	LOCK_OPEN_OUTLINE: 'lock-open-outline',
	LOCK_OUTLINE: 'lock-outline',
	MAGNIFY: 'magnify',
	MAP_MARKER: 'map-marker',
	MAP_MARKER_OUTLINE: 'map-marker-outline',
	MENU: 'menu',
	MENU_DOWN: 'menu-down',
	MENU_LEFT: 'menu-left',
	MENU_RIGHT: 'menu-right',
	MENU_UP: 'menu-up',
	MINUS: 'minus',
	MINUS_CIRCLE_OUTLINE: 'minus-circle-outline',
	PERSON: 'person',
	PERSON_OUTLINE: 'person-outline',
	PHONE: 'phone',
	PHONE_OUTLINE: 'phone-outline',
	PLUS: 'plus',
	PLUS_CIRCLE_OUTLINE: 'plus-circle-outline',
	PROGRESS_ALERT: 'progress-alert',
	SORT_VARIANT_LOCK: 'sort-variant-lock',
	TEXT_BOX_OUTLINE: 'text-box-outline',
	TEXT_BOX_SEARCH_OUTLINE: 'text-box-search-outline',
	TEXT_SEARCH: 'text-search',
	TIMETABLE: 'timetable',
	TRASH_CAN: 'trash-can',
	TRASH_CAN_OUTLINE: 'trash-can-outline',
	TRUCK_FAST_OUTLINE: 'truck-fast-outline',
	TRUCK_OUTLINE: 'truck-outline',
	TWITTER: 'twitter',
	WINDOW_CLOSE: 'window-close',
	YOUTUBE: 'youtube',
	GPS: 'gps',
	DOOR: 'meeting-room'
};

export default ICONS;
