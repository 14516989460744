/* eslint react-app/import/no-webpack-loader-syntax: off */
import IG_LOGO from '-!svg-react-loader!@assets/images/eb-logo-01-1x.svg';
import U2705 from '@assets/images/u2705.png';

export const VISUALS = {
	IG_LOGO,
	U2705,
};

export default {
	IG_LOGO,
	U2705,
};
