// Imports => Constants
import { ENDPOINTS } from '@constants';

export class CampaignsAPI {
	constructor(Client, config) {
		this.Client = Client;
		this.config = config;
	}

	list = data => {
		return this.Client.get(ENDPOINTS.CAMPAIGNS.LIST).then(
			response => response.data.data
		);
	};

	get_by_id = (id, data = {}) => {
		return this.Client.get(ENDPOINTS.CAMPAIGNS.SHOW(id), {
			params: { ...data },
		}).then(response => response.data.data);
	};

	get_cheating_list = () => {
		return this.Client.get(ENDPOINTS.CAMPAIGNS.CHEATING_LIST).then(
			response => response.data.data
		);
	};

	get_tips_and_tricks = () => {
		return this.Client.get(ENDPOINTS.CAMPAIGNS.TIPS_AND_TRICKS).then(
			response => response.data.data
		);
	};

	start = data => {
		return this.Client.post(ENDPOINTS.CAMPAIGNS.START, data).then(
			response => response
		);
	};

	end = data => {
		return this.Client.post(ENDPOINTS.CAMPAIGNS.END, data).then(
			response => response
		);
	};

	signup = data => {
		return this.Client.post(ENDPOINTS.CAMPAIGNS.SIGNUP, data).then(
			response => response
		);
	};

	signout = data => {
		return this.Client.post(ENDPOINTS.CAMPAIGNS.SIGNOUT, data).then(
			response => response
		);
	};
}

export default CampaignsAPI;
