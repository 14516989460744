// Imports => Vendor
import axios from 'axios';
import config from '@config';

// Imports => API
import AuthAPI from '@api/auth.api';
import CampaignsAPI from '@api/campaigns.api';
import ConversationAPI from '@api/conversation.api';
import ProfileAPI from '@api/profile.api';

const onUploadProgress = event => {
	// console.group('[Axios] => fn.onUploadProgress');
	// console.log('Event: ', event);
	// console.groupEnd();
};

const onDownloadProgress = event => {
	// console.group('[Axios] => fn.onDownloadProgress');
	// console.log('Event: ', event);
	// console.groupEnd();
};

const unauthenticatedState = state => {
	var unauthenticatedEvent = new CustomEvent('unAuthenticate', {
		detail: state,
	});
	window.dispatchEvent(unauthenticatedEvent);
};

let _timeOut = null;
let auth = null;

const addInterceptors = requestClient => {
	requestClient.interceptors.response.use(
		response => {
			return response;
		},
		error => {
			if (error && error.response && error.response.status === 401) {
				clearTimeout(_timeOut);

				_timeOut = setTimeout(() => {
					auth = false;
					unauthenticatedState(auth);
				}, 500);
			}
			return Promise.reject(error);
		}
	);
};

export default () => {
	const Client = axios.create({
		...config.api,
		onUploadProgress,
		onDownloadProgress,
	});
	addInterceptors(Client);

	const NoAuthClient = axios.create({
		...config.api_no_auth,
		onUploadProgress,
		onDownloadProgress,
	});
	addInterceptors(NoAuthClient);

	const auth = new AuthAPI(Client, NoAuthClient, config);
	const campaigns = new CampaignsAPI(Client, config);
	const conversation = new ConversationAPI(Client, config);
	const profile = new ProfileAPI(Client, config);

	return {
		auth,
		campaigns,
		conversation,
		profile,
	};
};
