// Imports => Constants
import { ENDPOINTS } from '@constants';

export class AuthAPI {
	constructor(Client, NoAuthClient, config) {
		this.Client = Client;
		this.NoAuthClient = NoAuthClient;
		this.config = config;
	}

	login = data => {
		console.log(ENDPOINTS.OAUTH.LOGIN);
		return this.NoAuthClient.post(ENDPOINTS.OAUTH.LOGIN, data).then(
			response => response.data.data
		);
	};

	forgot_password = data => {
		return this.NoAuthClient.post(ENDPOINTS.OAUTH.FORGOT_PASSWORD, data).then(
			response => response.data.data
		);
	};

	reset_password = data => {
		return this.NoAuthClient.post(ENDPOINTS.OAUTH.RESET_PASSWORD, data).then(
			response => response.data.data
		);
	};
}

export default AuthAPI;
