export const KEYS = {
  ACCESS_TOKEN: 'access_token',
  ACCOUNT: 'account',
  ANSWER: 'answer',
  ATTEMPTS: 'attempts',
  BADGE: 'badge',
  CAMPAIGN: 'campaign',
  CAMPAIGNS: 'campaigns',
  CHEATING_LIST: 'cheating_list',
  CLOSEABLE: 'closeable',
  CODE: 'code',
  COMMENT: 'comment',
  CONVERSATION: 'conversation',
  DEFAULT: 'default',
  EMAIL: 'email',
  ENCOURAGEMENT: 'encouragement',
  ERROR: 'error',
  EXPIRES_AT: 'expires_at',
  EXPIRES_IN: 'expires_in',
  EXTRA_QUESTION: 'extra_question',
  LOADED: 'loaded',
  LOCALE: 'locale',
  LOCKED: 'locked',
  LOCKED_TIMER: 'locked_timer',
  MODAL: 'modal',
  NAME: 'name',
  NAVIGATION: 'navigation',
  NO_SESSION: 'no-session-event',
  OPEN: 'open',
  PINCODE: 'pincode',
  PINCODE_CONFIRM: 'pincodeConfirm',
  POSTALCODE: 'postal-code',
  PROFILE: 'profile',
  QUESTION: 'question',
  QUESTIONS: 'questions',
  REFRESH_TOKEN: 'refresh_token',
  RESULT: 'result',
  SCALE: 'scale',
  SCORE: 'score',
  SCROLLER: 'ac-scroller',
  SELECT: 'select',
  SELECTED: 'selected',
  SELECTED_CAMPAIGN_ID: 'selected_campaign_id',
  SMILEY: 'smiley',
  SUBSCRIBED: 'subscribed',
  SUPPORT_EMAIL_ADDRESS: 'service@vvd.nl',
  SURPRISE: 'surprise',
  TIP: 'tip',
  TIPS_AND_TRICKS: 'tips_and_tricks',
  USER: 'user',
  QR: 'qr',
  VISIBLE: 'visible',
  XUSR_TOKEN: 'xusr_token',
};

export default KEYS;
