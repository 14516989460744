const CAMPAIGNS = '/campaign-actions';
const CHEATING_LETTER = '/cheating-letter';
const TIPS_AND_TRICKS = '/tips-and-tricks';
const CHANGE_ACTIVE_MUNICIPALITY = '/change-active-municipality';
const END_ACTION = '/end-action';
const FORGOT_PASSWORD = '/forgot-password';
const LOGIN = '/login';
const LOGOUT = '/logout';
const PROFILE = '/profile';
const RESET_PASSWORD = '/reset-password';
const SIGN_OUT = '/sign-out';
const SIGN_UP = '/sign-up';
const START_ACTION = '/start-action';
const UI = '/ui';
const NOT_INTERESTED = '/no-interest';
const ABSENT = '/absent';
const CONVERSATION = '/conversation';
const RESPONSES = '/responses';

// V1
export const ENDPOINTS = {
	OAUTH: {
		LOGIN: `${UI}${LOGIN}`, // POST
		FORGOT_PASSWORD: `${UI}${FORGOT_PASSWORD}`, // POST
		RESET_PASSWORD: `${UI}${RESET_PASSWORD}`, // POST
		LOGOUT: `${UI}${LOGOUT}`, // POST
	},
	PROFILE: {
		WHOAMI: `${UI}${PROFILE}`, // GET
		UPDATE: `${UI}${PROFILE}`, // POST
		CHANGE_ACTIVE_MUNICIPALITY: `${UI}${PROFILE}${CHANGE_ACTIVE_MUNICIPALITY}`, // POST
	},
	CAMPAIGNS: {
		LIST: `${UI}${CAMPAIGNS}`, // GET
		SHOW: (id) => `${UI}${CAMPAIGNS}/${id}`, // GET
		START: `${UI}${CAMPAIGNS}${START_ACTION}`, // POST
		END: `${UI}${CAMPAIGNS}${END_ACTION}`, // POST
		SIGNUP: `${UI}${CAMPAIGNS}${SIGN_UP}`, // POST
		SIGNOUT: `${UI}${CAMPAIGNS}${SIGN_OUT}`, // POST
		CHEATING_LIST: `${UI}${CHEATING_LETTER}`, // GET
		TIPS_AND_TRICKS: `${UI}${TIPS_AND_TRICKS}`, // GET
	},
	CONVERSATION: {
		GET: `${UI}${RESPONSES}${CONVERSATION}`, // GET
		FINISH: `${UI}${RESPONSES}${CONVERSATION}`, // POST
		ABSENT: `${UI}${RESPONSES}${ABSENT}`, // POST
		NOT_INTERESTED: `${UI}${RESPONSES}${NOT_INTERESTED}`, // POST
	},
};

export default ENDPOINTS;
