export const POSITIONS = {
	TOP: 'top',
	RIGHT: 'right',
	BOTTOM: 'bottom',
	LEFT: 'left',
};

export const SIZES = {
	SMALL: 'small',
	DEFAULT: 'default',
	MEDIUM: 'medium',
	LARGE: 'large',
};

export const THEMES = {
	ALPHA: 'alpha',
	ALPHA_LIGHT: 'alpha_light',
	OMEGA: 'omega',
	MINTY: 'minty',
	BETA: 'beta',
	DEFAULT: 'default',
	TRANSPARENT: 'transparent',
	WHITE: 'white',
	LIGHT: 'light',
	SUBTLE: 'subtle',
	MEDIUM: 'medium',
	DARK: 'dark',
	PITCH: 'pitch',
	PLACEHOLDER: 'placeholder',
	PATTERN: 'pattern',
	FACEBOOK: 'facebook',
	INSTAGRAM: 'instagram',
	YOUTUBE: 'youtube',
	TWITTER: 'twitter',
};

export const TYPES = {
	TEXT: 'text',
	TEXTAREA: 'textarea',
	PASSWORD: 'password',
	NUMBER: 'number',
	EMAIL: 'email',
	RADIO: 'radio',
	SUBMIT: 'submit',
	BUTTON: 'button',
	BACKGROUND: 'background',
	IMAGE: 'image',
	HIDDEN: 'hidden',
};

export const VARIANTS = {
	OUTLINE: 'outline',
	DEFAULT: 'default',
	SUBTLE: 'subtle',
	TRANSPARENT: 'transparent',
	UPPERCASE: 'uppercase',
	LOWERCASE: 'lowercase',
	SUCCESS: 'success',
	ERROR: 'error',
	WARNING: 'warning',
	INFO: 'info',
	HELP: 'help',
	PLACEHOLDER: 'placeholder',
};
